var __jsx = React.createElement;
import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'js/store/store';
import { DefaultSeo } from 'next-seo';
import { SEO } from 'next-seo.config';
import { datadogLogs } from '@datadog/browser-logs';
import ErrorComponent from 'pages/_error';
import { ErrorBoundary } from 'react-error-boundary';
import 'js/utils/googleAnalytics';
import 'js/utils/googleTagManager/head';
import 'js/utils/datadog';
import { ENVIRONMENTS } from 'js/const/env/env';
if (ENVIRONMENTS.API_MOCKING === 'enabled') {
  require('mock');
}
const App = _ref => {
  let {
    Component,
    pageProps
  } = _ref;
  const errorHandler = (error, info) => datadogLogs.getLogger('criticalErrorLogger').error('Reactコンポーネント内でエラーが発生しました', {
    error_detail: {
      name: error.name,
      message: error.message,
      stack: error.stack,
      info
    }
  });
  return __jsx(Provider, {
    store: store
  }, __jsx(DefaultSeo, SEO), __jsx(ErrorBoundary, {
    FallbackComponent: () => __jsx(ErrorComponent, {
      statusCode: 500
    }),
    onError: errorHandler
  }, __jsx(Component, pageProps)));
};
export default App;